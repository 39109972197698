import React, {FC, useLayoutEffect} from 'react'
import {Provider} from 'react-redux'

import {store} from './store/store'
import RoutesWrapper from './components/AppLayout/RoutesWrapper'
import {routes} from './utils/constants'
import {useLocation} from 'react-router-dom';



const Wrapper: FC<any> = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};


function App() {
    return (
        <Provider store={store}>
            <Wrapper>
                <RoutesWrapper routes={routes}/>
            </Wrapper>
        </Provider>
    )
}

export default App
