import React, {FC} from 'react';
import './AppTitle.scss'
import SvgSelector from '../SvgSelector/SvgSelector';


interface PropsAppTitle {
    title: string
    children: any
}

export const AppTitle: FC<PropsAppTitle> = ({title, children}) => {
    return (
        <div className={'app-title'}>
            <div className={'app-title__icon'}>
                {children}
            </div>
            <h2 className={'app-title__title'}>{title}</h2>
        </div>
    );
};
