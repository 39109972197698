export const RouterPath = {
    home: '/',
    evacuation: '/evacuation'
}

export const routes: RoutesType[] = [
    {path: RouterPath.home, mode: 'home'},
    {path: RouterPath.evacuation, mode: 'evacuation'},
    {path: '*', mode: 'error'}
]

export interface RoutesType {
    path: string
    mode: string
}