import React from 'react';
import {AppTitle} from '../AppTitle/AppTitle';
import SvgSelector from '../SvgSelector/SvgSelector';
import {AppButton} from '../AppButton/AppButton';
import './Locations.scss'

export const Locations = () => {
    return (
        <div className={'locations'}>
            <div className={'locations__title'}>
                <AppTitle title={'Nearest locations'}>
                    <SvgSelector id={'locations-icon'}/>
                </AppTitle>
            </div>
            <div className={'locations-content'}>
                <div className={'locations-content-block'}>
                    <h3 className={'locations-content-block__title'}>Pharmacy</h3>
                    <h3 className={'locations-content-block__subtitle'}>Guzelyurt Pharmacy</h3>
                    <h3 className={'locations-content-block__text'}>Güzelyurt Mah, Şht. Komando Er Mustafa Göktürk
                        Cd. No:16, 07110 Aksu/Antalya</h3>
                    <a href="tel: 02424631045" className={'locations-content-block__phone'}>
                        <SvgSelector id={'phone-icon'}/>
                        (0242) 4631045
                    </a>
                    <div className={'locations-content-block__buttons'}>
                        <div className={'locations-content-block__button'}>
                            <a href={'https://goo.gl/maps/qy1aGFxVVLGZ8vEU7'} target={'_blank'} >
                                <AppButton mode={'darkBlue'}>
                                    <SvgSelector id={'maps-icon'}/>
                                    Open in Maps
                                </AppButton>
                            </a>
                        </div>
                        <div className={'locations-content-block__button'}>
                            <a href="tel: 02424631045" target={'_blank'} className={'locations-content-block__phone'}>
                                <AppButton mode={'blue'}>
                                    <SvgSelector id={'call-icon'}/>
                                    To call
                                </AppButton>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'locations-content-block'}>
                    <h3 className={'locations-content-block__title'}>Hospital</h3>
                    <a href="https://www.yasamhastaneleri.com/hastane_antalya-yasam-hospital_3343_11900_en#bolumlerimiz" target={'_blank'}>
                        <h3 className={'locations-content-block__subtitle'}>Antalya Yaşam Hastanesi</h3>
                    </a>
                    <h3 className={'locations-content-block__text'}>Şirinyalı mah. 1487 sk. No:4
                        Semt Dedeman Merkez</h3>
                    <a href="tel: 02423108080" className={'locations-content-block__phone'}>
                        <SvgSelector id={'phone-icon'}/>
                        (0242) 310-8080
                    </a>
                    <div className={'locations-content-block__buttons'}>
                        <div className={'locations-content-block__button'}>
                            <a href={'https://www.google.com/maps/place/36%C2%B051\'49.1%22N+30%C2%B043\'50.5%22E/@36.8636389,30.7306944,17z/data=!3m1!4b1!4m4!3m3!8m2!3d36.8636389!4d30.7306944?entry=ttu'} target={'_blank'} >
                                <AppButton mode={'darkBlue'}>
                                    <SvgSelector id={'maps-icon'}/>
                                    Open in Maps
                                </AppButton>
                            </a>
                        </div>
                        <div className={'locations-content-block__button'}>
                            <a href="tel: 02423108080" className={'locations-content-block__phone'} target={'_blank'} >
                                <AppButton mode={'blue'}>
                                    <SvgSelector id={'call-icon'}/>
                                    To call
                                </AppButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

