import React, {FC, useEffect, useRef, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import SvgSelector from '../../components/SvgSelector/SvgSelector'
import expoCentre from '../../assets/img/first-screen/expo-centre.jpg'
import {Loading} from '../../components/Loading/Loading'
import {useAppSelector} from '../../store/store'
import './Home.scss'
import {AppTitle} from '../../components/AppTitle/AppTitle'
import {Contacts} from '../../components/Contacts/Contacts'
import {Locations} from '../../components/Locations/Locations';
import AppPopup from '../../components/AppPopup/AppPopup';
import {blockScrolling} from '../../utils/blockScrolling';
import groundFloorHall11 from '../../assets/img/floor-plan/groundFloorHall1.jpg';
import groundFloor1 from '../../assets/img/floor-plan/groundFloor.jpg';
import floor11 from '../../assets/img/floor-plan/floor1.jpg';
import floor31 from '../../assets/img/floor-plan/floor3.jpg';
import {AppButton} from '../../components/AppButton/AppButton';
import {Helmet} from 'react-helmet';

export const Home = () => {
    const {isInitializeApp} = useAppSelector(state => state.app)
    const navigate = useNavigate()
    const headerRef = useRef<any | null>(null);
    const planRef = useRef<any | null>(null);
    const contactsRef = useRef<any | null>(null);
    const locationsRef = useRef<any | null>(null);

    // const scroll = useScrollPosition()
    // console.log(scroll)


    const groundFloorHall1 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FFloorPlanImages%2FgroundFloorHall1.jpg?alt=media&token=8b10b36a-b5e0-48b2-b885-473fa16f362a&_gl=1*1ccdkjf*_ga*MTQ4OTk3Njg0MS4xNjc4MDUxNjY1*_ga_CW55HF8NVT*MTY4NTYxNjMyMC43MC4xLjE2ODU2MTY1NjYuMC4wLjA.'
    const groundFloor = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FFloorPlanImages%2FgroundFloor.jpg?alt=media&token=0f6e2732-0d03-4100-ac01-0508584b8042' && groundFloor1
    const floor1 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FFloorPlanImages%2Ffloor1.jpg?alt=media&token=521d64e0-aff8-4188-97d9-5900c62e0e15' && floor11
    const floor3 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FFloorPlanImages%2Ffloor3.jpg?alt=media&token=3c4a5391-0a4c-40e1-abd6-081ae99adfbe' && floor31

    const handleClick = (value: any) => {
        value.current.scrollIntoView({behavior: 'smooth'});
    };

    const [epText, setEpText] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setEpText(!epText);
        }, 5000);
        return () => clearInterval(interval);
    }, [epText]);

    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [activeImgPopup, setActiveImgPopup] = useState('');


    const popupIsOpenHandler = (value: string) => {
        setActiveImgPopup(value)
        setPopupIsOpen(!popupIsOpen)
    }


    let bottom
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        bottom = '30px'
    } else {
        bottom = '20px'
    }

    useEffect(() => {
        if (popupIsOpen) {
            blockScrolling(true)
        } else {
            blockScrolling(false)
        }
        return () => (blockScrolling(false));
    }, [popupIsOpen]);


    if (!isInitializeApp) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Congress 2023"
                />
                <title>Congress 2023</title>
            </Helmet>
            <div ref={headerRef} className={'home'}>
                <div className={'header home__header'}>
                    <div className={'home__container'}>
                        <div className={'menu'}>
                            <div onClick={() => handleClick(planRef)} className={'menu__item'}>Floor plan</div>
                            <div onClick={() => handleClick(contactsRef)} className={'menu__item'}>Contacts</div>
                            <div onClick={() => handleClick(locationsRef)} className={'menu__item'}>Nearest locations
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'main home__main'}>
                    <div className={'main-first-screen'}>
                        <div className={'main-first-screen__image'}>
                            <img src={expoCentre} alt={'statistics-icon'}/>
                        </div>
                        <div className={'home__container'}>
                            <h1 className={'main-first-screen__title'}>Antalya Regional Convention 2023</h1>
                        </div>
                    </div>
                    <div ref={planRef} className={'main-floor-plan'}>
                        <div className={'home__container'}>
                            <AppTitle title={'Floor plan and departments'}>
                                <SvgSelector id={'plan-section-icon'}/>
                            </AppTitle>
                            <div className={'plan-info-wrap'}>
                                <Accordion title={'Ground floor — Hall 1'} number={1} img={groundFloorHall1}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(groundFloorHall1)}/>
                                <Accordion title={'Ground floor'} number={2} img={groundFloor}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(groundFloor)}/>
                                <Accordion title={'1 floor'} number={3} img={floor1}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(floor1)}/>
                                <Accordion title={'3 floor'} number={4} img={floor3}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(floor3)}/>
                            </div>
                        </div>
                        <div className={'main-floor-plan__image'}>

                        </div>
                    </div>
                    <div ref={contactsRef} className={'main-contacts'}>
                        <div className={'home__container'}>
                            <AppTitle title={'Contacts'}>
                                <SvgSelector id={'contacts-icon'}/>
                            </AppTitle>
                            <div className={'main-contacts__header'}>
                                <div className={'main-contacts__header_block'}>
                                    <h3 className={'main-contacts__header_title'}>HLC Chairman</h3>
                                    <Contacts name={'Schubert, Stephan (Ferit)'} phone={'90 242 243 4414'}
                                              email={'1SchubertFerit@jwpub.org'} border={false}/>
                                </div>
                                <div className={'main-contacts__header_block'}>
                                    <h3 className={'main-contacts__header_title'}>Service Department Overseer at the
                                        branch</h3>
                                    <Contacts name={'Aret Izci'} phone={'90 534 020 3011'} border={false}/>
                                </div>
                            </div>
                            <div className={'main-contacts__main'}>
                                <h3 className={'main-contacts__main_title'}>Regional Convention Committee</h3>
                                <div className={'main-contacts-blocks'}>
                                    <div className={'main-contacts-blocks__item'}>
                                        <Contacts title={'Convention Committee Coordinator'} name={'Anton Boykov'}
                                                  phone={'90 551 081 4446'}/>
                                    </div>
                                    <div className={'main-contacts-blocks__item'}>
                                        <Contacts title={'Program Overseer'} name={'Aleksey Ziganshin'}
                                                  phone={'90 543 910 0379'}/>
                                    </div>
                                    <div className={'main-contacts-blocks__item'}>
                                        <Contacts title={'Rooming Overseer'} name={'Dmitry Bashmakov'}
                                                  phone={'90 534 513 7691'}/>
                                    </div>
                                    <div className={'main-contacts-blocks__item'}>
                                        <Contacts title={'Chairman of the 1st Regional Convention'} name={'Pedro Vega'}
                                                  phone={'90 534 485 1946'}/>
                                    </div>
                                    <div className={'main-contacts-blocks__item'}>
                                        <Contacts title={'Chairman of the 2nd and 3rd Regional Convention'}
                                                  name={'Valery Lazebnyy'}
                                                  phone={'90 551 190 6176'} border={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={locationsRef} className={'main-contacts-locations'}>
                        <div className={'home__container'}>
                            <Locations/>
                        </div>
                    </div>
                </div>
                <div className={'footer home__footer'}>
                    <div className={'home__container'}>
                        <div className={'footer__body'}>
                            <div className={'menu'}>
                                <div onClick={() => handleClick(planRef)} className={'menu__item'}>Floor plan</div>
                                <div onClick={() => handleClick(contactsRef)} className={'menu__item'}>Contacts</div>
                                <div onClick={() => handleClick(locationsRef)} className={'menu__item'}>Nearest
                                    locations
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupIsOpen && <div className={'popup-image'} onClick={() => setPopupIsOpen(false)}>
                    <AppPopup image={activeImgPopup} closePopup={() => setPopupIsOpen(false)}/>
                </div>}
                <div className={'home__container'}>
                    <div className={'evacuation-wrap'}>
                        <div style={{bottom: `${bottom}`}} className={'evacuation-button'}>
                            <Link to={'evacuation'}>
                                <AppButton mode={'orange'}> <SvgSelector id={'alert-icon'}/><span
                                    className={epText ? 'evacuation-button__wrap' : 'evacuation-button__wrap'}><span
                                    className={epText ? 'evacuation-button__span active' : 'evacuation-button__span'}><span>Evacuation plan</span><span>План эвакуации</span></span></span></AppButton>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

interface AccordionType {
    title: string
    number: number
    img: string
    popupIsOpenHandler: (img: string) => void
    color?: string[]
}


export const Accordion: FC<AccordionType> = ({title, number, img, popupIsOpenHandler, color}) => {
    const [isViewFloorPlan, setIsViewFloorPlan] = useState(0);


    let back
    let colorText
    if (color) {
        back = color[0]
        colorText = color[1]
    }

    const viewFloorPlanHandler = (value: number) => {
        if (value !== 0 && value !== isViewFloorPlan) {
            setIsViewFloorPlan(value)
        } else {
            setIsViewFloorPlan(0)
        }
    }

    return (
        <div className={'plan-info'} style={{background: back}}>
            <div onClick={() => viewFloorPlanHandler(number)} className={'plan-info-static'}>
                <div className={'plan-info-static__title'}>
                    <h3 style={{color: colorText}}>{title}</h3>
                </div>
                <div className={isViewFloorPlan === number ? 'plan-info__button active' : 'plan-info__button'}>
                    <SvgSelector id={'arrow-accordion-icon'} svgColor={colorText || '#1B2A72'}/>
                </div>
            </div>
            <div className={'plan-info-dynamism'}>
                <div
                    className={isViewFloorPlan === number ? 'plan-info-dynamism__floor active' : 'plan-info-dynamism__floor'}>
                    <div
                        className={isViewFloorPlan === number ? 'plan-info-dynamism__image active' : 'plan-info-dynamism__image'}>
                        <img onClick={() => popupIsOpenHandler(img)}
                             src={img} alt={'floor-three'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}