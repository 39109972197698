import React, {FC} from 'react';
import {AppTitle} from '../AppTitle/AppTitle';
import SvgSelector from '../SvgSelector/SvgSelector';
import './Contacts.scss'
import {AppButton} from '../AppButton/AppButton';


interface ContactType {
    title?: string
    name: string
    phone: string
    email?: string
    border?: boolean
}

export const Contacts: FC<ContactType> = ({title, name, phone, email, border = true}) => {

    const phoneTrim = phone.replace(/ /g,'')

    return (
        <div className={ border ? 'contacts-card border-bottom' : 'contacts-card'}>
            <div className={'contacts-card__header'}>
                { title && <h4 className={'contacts-card__title'}>
                    {title}
                </h4>}
            </div>
            <div className={'contacts-card__main'}>
                <div className={'main-contacts-content'}>
                    <div className={'main-contacts-content__item'}>
                        <SvgSelector id={'user-icon'}/>
                        <h4>{name}</h4>
                    </div>
                    <div className={'main-contacts-content__item'}>
                        <SvgSelector id={'phone-icon'}/>
                        <h4>+{phone}</h4>
                    </div>
                    {email && <div className={'main-contacts-content__item'}>
                        <SvgSelector id={'message-icon'}/>
                        <a href={'mailto:1SchubertFerit@jwpub.org'} target={'_blank'} >{email}</a>
                    </div>}
                </div>
                <div className={'contacts-card__buttons'}>
                    <div className={'contacts-card__button whatsapp-button'}>
                        <a href={`https://wa.me/${phoneTrim}`} className={'locations-content-block__phone'} target={'_blank'} >
                            <AppButton mode={'green'}>
                                <SvgSelector id={'whatsapp-icon'}/>
                                Call on WhatsApp
                            </AppButton>
                        </a>

                    </div>
                    <div className={'contacts-card__button call-button'}>
                        <a href={`tel:${phone}`} className={'locations-content-block__phone'} target={'_blank'} >
                            <AppButton mode={'blue'}>
                                <SvgSelector id={'call-icon'}/>
                                Call by phone
                            </AppButton>
                        </a>
                    </div>
                </div>
            </div>
            <div className={'contacts-card__footer'}>

            </div>
        </div>
    );
};

