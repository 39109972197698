import React, {useEffect, useState} from 'react';
import styles from './EvacuationPlan.module.scss'
import {Accordion} from '../home/Home';
import {AppButton} from '../../components/AppButton/AppButton';
import SvgSelector from '../../components/SvgSelector/SvgSelector';
import {useNavigate} from 'react-router-dom';
import {RouterPath} from '../../utils/constants';
import AppPopup from '../../components/AppPopup/AppPopup';
import {blockScrolling} from '../../utils/blockScrolling';
import { Helmet } from 'react-helmet'


export const EvacuationPlan = () => {
    const navigate = useNavigate()


    const groundFloorHall1 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FEvacuationPlan%2F1.jpg?alt=media&token=7c6b6f38-1809-4e85-91e1-6f43a3b8654a&_gl=1*1tyk0xr*_ga*MTQ4OTk3Njg0MS4xNjc4MDUxNjY1*_ga_CW55HF8NVT*MTY4NTY0NTQ4OS43Mi4xLjE2ODU2NDU2MTYuMC4wLjA.'
    const groundFloor = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FEvacuationPlan%2F2.jpg?alt=media&token=5077e7a0-e05b-40ee-9b22-ba2be07f6ddf&_gl=1*39sryb*_ga*MTQ4OTk3Njg0MS4xNjc4MDUxNjY1*_ga_CW55HF8NVT*MTY4NTY0NTQ4OS43Mi4xLjE2ODU2NDU2NTguMC4wLjA.'
    const floor1 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FEvacuationPlan%2F3.jpg?alt=media&token=92441757-8b64-4545-bb7e-89d29cecd319&_gl=1*e9suta*_ga*MTQ4OTk3Njg0MS4xNjc4MDUxNjY1*_ga_CW55HF8NVT*MTY4NTY0NTQ4OS43Mi4xLjE2ODU2NDU3MDMuMC4wLjA.'
    const floor3 = 'https://firebasestorage.googleapis.com/v0/b/kaleici-org.appspot.com/o/AntalyaRegionalConvention2023%2FEvacuationPlan%2F4.jpg?alt=media&token=6a6e2be0-0b9a-48a2-b539-99d21956ecda&_gl=1*1b61tm1*_ga*MTQ4OTk3Njg0MS4xNjc4MDUxNjY1*_ga_CW55HF8NVT*MTY4NTY0NTQ4OS43Mi4xLjE2ODU2NDU3MTUuMC4wLjA.'


    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [activeImgPopup, setActiveImgPopup] = useState('');
    const [languageManagement, setLanguageManagement] = useState(false);


    useEffect(() => {
        if (popupIsOpen) {
            blockScrolling(true)
        } else {
            blockScrolling(false)
        }
        return () => (blockScrolling(false));
    }, [popupIsOpen]);

    useEffect(() => {
        window.scrollBy(0, 0)
    }, [])


    const popupIsOpenHandler = (value: string) => {
        setActiveImgPopup(value)
        setPopupIsOpen(!popupIsOpen)
    }


    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Evacuation plan'
                />
                <title>Evacuation plan</title>
            </Helmet>
            <div className={styles.ep}>
                <div className={styles.ep__container}>
                    <div className={styles.ep__body}>
                        <div className={styles.ep__header}>
                            <div className={styles.ep__button} onClick={() => navigate(RouterPath.home)}>
                                <AppButton mode={'black'}> <SvgSelector id={'right-arrow'}
                                                                        className={'turn_in_opposite_dir'}/>Back</AppButton>
                            </div>
                            <h1 className={styles.ep__title}>Evacuation plan</h1>
                        </div>
                        <div className={styles.ep__main}>
                            <div>
                                <div className={'main-floor-plan'}>
                                    <div className={'plan-info-wrap'}>
                                        <Accordion title={'Ground floor — Hall 1 — 3024 seats'} number={1}
                                                   img={groundFloorHall1}
                                                   popupIsOpenHandler={(e) => popupIsOpenHandler(groundFloorHall1)}
                                                   color={['#17181D', '#fff']}
                                        />
                                        <Accordion title={'Ground floor'} number={2} img={groundFloor}
                                                   popupIsOpenHandler={(e) => popupIsOpenHandler(groundFloor)}
                                                   color={['#17181D', '#fff']}
                                        />
                                        <Accordion title={'1 Floor'} number={3} img={floor1}
                                                   popupIsOpenHandler={(e) => popupIsOpenHandler(floor1)}
                                                   color={['#17181D', '#fff']}/>
                                        <Accordion title={'3 floor'} number={4} img={floor3}
                                                   popupIsOpenHandler={(e) => popupIsOpenHandler(floor3)}
                                                   color={['#17181D', '#fff']}/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={languageManagement ? `${styles.main__manual} ${styles.active}` : `${styles.main__manual}`}>
                                <div className={styles.language_management}>
                                    <div
                                        className={!languageManagement ? `${styles.language} ${styles.active}` : `${styles.language}`} onClick={() => setLanguageManagement(false)}>EN
                                    </div>
                                    <div
                                        className={languageManagement ? `${styles.language} ${styles.active}` : `${styles.language}`}
                                        onClick={() => setLanguageManagement(true)}>РУС
                                    </div>
                                </div>
                                <div className={styles.main__manual_header}>
                                    <h2 className={styles.main__title}>{languageManagement ? 'План готовности к чрезвычайным ситуациям' : 'Emergency Preparedness Plan'}</h2>
                                </div>
                                <div className={styles.main__block}>
                                    <h3 className={styles.main__subtitle}>{languageManagement ? 'Контактные номера экстренных служб в Турции' : 'Emergency Contact Numbers in Turkey'}</h3>
                                    <p>{languageManagement ? 'Полиция - наберите 155' : 'Police - Dial 155'} </p>
                                    <p>{languageManagement ? 'Пожарная служба - наберите 110' : 'Fire - Dial 110'} </p>
                                    <p>{languageManagement ? 'Скорая помощь - наберите 112' : 'Ambulance - Dial 112'} </p>
                                </div>
                                <div className={styles.main__block}>
                                    <p>
                                        {languageManagement ? `В случае чрезвычайной ситуации, когда эвакуация конференц-центра становится необходимой, посетители должны соблюдать следующие рекомендации. В НИКАКОМ случае не следует использовать лифты в чрезвычайной ситуации.` : `In the event of an emergency where evacuation of the convention facility becomes
                                    necessary, attendants should observe the following guidelines. Under NO
                                    circumstances should the elevators be used in an emergency.`}

                                    </p>
                                </div>
                                <div className={styles.main__block}>
                                    <h3 className={styles.main__subtitle}>{languageManagement ? `Первый этаж - Главный конференц-зал` : `Ground Floor - Main Convention Hall`} </h3>
                                    <p>
                                        {languageManagement ? `Персонал сцены, секции A, B и C, а также те, кто находится в рядах с 1 по 8 в секциях D, E и F, должны покинуть здание через Выход 1 (E1).` : `Backstage personnel, Sections A, B and C as well as those in rows 1 through 8 of
                                    sections D, E and F should vacate the building through Exit 1 (E1).`}
                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в рядах с 9 по 17 в секциях D, E и F, а также те, кто находится в секциях G, H и I, должны покинуть здание через Выход 2 (E2).` : `Those seated in rows 9 through 17 of sections D, E and F as well as those in
                                    sections G, H and I should vacate the building through Exit 2 (E2).`}

                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места и помогает в различных отделах в задней части зала, должны покинуть здание через Выход 3 (E3).` : `Those seated and assisting with the various departments at the back of the Hall
                                    should vacate the building through Exit 3 (E3).`}

                                    </p>
                                    <p>
                                        {languageManagement ? `Альтернативный маршрут для VIP-персон от задней сцены. Покиньте главный зал через Выход 4 (E4). Поверните направо и следуйте по стене до главного фойе. Затем пройдите через двери левого фойе.` : `Alternate VIP route from Backstage. Vacate the Main Hall through Exit 4 (E4)
                                    Turn to the right and follow the wall to the main foyer. Then proceed through
                                    the far left foyer doors.`}
                                    </p>
                                    <b>
                                        {languageManagement ? `*После выхода наружу, пожалуйста, соберитесь вдали от здания на небольшой парковке, расположенной в юго-западном углу здания.` : ` *Once outside, please gather away from the building in the small parking lot
                                        located in the South West corner of the facility.`}
                                    </b>
                                </div>
                                <div className={styles.main__block}>
                                    <h3 className={styles.main__subtitle}>{languageManagement ? `Первый этаж - VIP 1` : `Ground Floor - VIP 1`} </h3>
                                    <p>
                                        {languageManagement ? `Те, кто работает или находится в комнате VIP 1 (Гардероб) или в фойе, должны покинуть здание через главные двери, расположенные в фойе.` : `Those working or present in the VIP 1 room (Baggage) or in the Foyer should
                                    vacate the building through the main doors located in the Foyer.`}
                                    </p>
                                    <b>
                                        {languageManagement ? `*После выхода наружу, пожалуйста, соберитесь вдали от здания на небольшой парковке, расположенной в юго-западном углу здания.` : `*Once outside, please gather away from the building in the small parking lot
                                    located in the South West corner of the facility.`}

                                    </b>
                                </div>
                                <div className={styles.main__block}>
                                    <h3 className={styles.main__subtitle}>{languageManagement ? `Второй этаж - Анфаш` : `Second Floor - Anfaş`} </h3>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции A, ряды с 1 по 5, должны покинуть зал через Выход 1 (E1) и спуститься по *Северо-восточному лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section A, rows 1 through 5 should vacate through Exit 1 (E1) and
                                    proceed down the *North East Stairwell to the main floor and exit through the
                                    foyer main doors.`}
                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции A, ряды с 6 по 11, должны покинуть зал через Выход 3 (E3) и спуститься по коридору к СВ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section A, rows 6 through 11 should vacate through Exit 3 (E3)
                                    and proceed down the corridor to the NE Stairwell to the main floor and exit
                                    through the foyer main doors.`}
                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции B, ряды с 1 по 5, должны покинуть зал через Выход 2 (E2) и спуститься по СВ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section B, rows 1 through 5 should vacate through Exit 2 (E2)
                                    and proceed down the North East Stairwell to the main floor and exit through the
                                    foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции B, ряды с 6 по 11, должны покинуть зал через Выход 4 (E4) и спуститься по коридору к СВ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section B, rows 6 through 11 should vacate through Exit 4 (E4)
                                    and proceed down the corridor to the NE Stairwell to the main floor and exit
                                    through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции C, ряды с 1 по 5, должны покинуть зал через Выход 5 (E5) и спуститься по коридору к СВ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section C, rows 1 through 5 should vacate through Exit 5 (E5)
                                    and proceed down the corridor to the NE Stairwell to the main floor and exit
                                    through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции D, ряды с 1 по 5, должны покинуть зал через Выход 6 (E6) и спуститься по коридору к СВ лестничному пролету на главный этаж и выйти через главные двери фойе.` : ` Those seated in Section D, rows 1 through 5 should vacate through Exit 6 (E6)
                                    and proceed down the corridor to the NE Stairwell to the main floor and exit
                                    through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции C, ряды с 6 по 11, должны покинуть зал через Выход 7 (E7) и спуститься по коридору к ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section C, rows 6 through 11 should vacate through Exit 7 (E7)
                                    and proceed down the corridor to the South West Stairwell to the main floor and
                                    exit through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции D, ряды с 6 по 11, должны покинуть зал через Выход 8 (E8) и спуститься по коридору к ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section D, rows 6 through 11 should vacate through Exit 8 (E8)
                                    and proceed down the corridor to the South West Stairwell to the main floor and
                                    exit through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции E, ряды с 1 по 5, должны покинуть зал через Выход 7 (E7) и спуститься по коридору к ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : ` Those seated in Section E, rows 1 through 5 should vacate through Exit 7 (E7)
                                    and proceed down the corridor to the South West Stairwell to the main floor and
                                    exit through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции E, ряды с 6 по 11, должны покинуть зал через Выход 9 (E9) и спуститься по ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : ` Those seated in Section E, rows 6 through 11 should vacate through Exit 9 (E9)
                                    and proceed down the SW Stairwell to the main floor and exit through the foyer
                                    main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции F, ряды с 1 по 5, должны покинуть зал через Выход 8 (E8) и спуститься по коридору к ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Those seated in Section F, rows 1 through 5 should vacate through Exit 8 (E8)
                                    and proceed down the corridor to the South West Stairwell to the main floor and
                                    exit through the foyer main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Те, кто занимает места в секции F, ряды с 6 по 11, должны покинуть зал через Выход 10 (E10) и спуститься по ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : ` Those seated in Section F, rows 6 through 11 should vacate through Exit 10 (E10)
                                    and proceed down the SW Stairwell to the main floor and exit through the foyer
                                    main doors.`}


                                    </p>
                                    <p>
                                        {languageManagement ? `Также все присутствующие в комнате Gül (комната для матери/ребенка) должны пройти по коридору к ЮЗ лестничному пролету на главный этаж и выйти через главные двери фойе.` : `Likewise anyone present in Gül (Mother/child room) should proceed down the
                                    corridor to the South West Stairwell to the main floor and exit through the
                                    foyer main doors.`}

                                    </p>
                                    <b>
                                        {languageManagement ? `*После выхода наружу, пожалуйста, соберитесь вдали от здания на небольшой парковке, расположенной в юго-западном углу здания.` : ` *Once outside, please gather away from the building in the small parking lot
                                    located in the South West corner of the facility.`}

                                    </b>
                                </div>
                                <div className={styles.main__block}>
                                    <h3 className={styles.main__subtitle}>{languageManagement ? `Третий этаж` : `Third Floor`} </h3>
                                    <p>
                                        {languageManagement ? `VIP 2 - Присутствующие в VIP 2 должны пройти по коридору к СВ лестничному пролету и выйти через главные двери фойе, налево.` : `VIP 2 - Those present in VIP 2 should proceed down the corridor to the North
                                    East Stairwell and exit through the foyer main doors to the left.`}
                                    </p>
                                    <p>
                                        {languageManagement ? `Комната Leylak - Присутствующие в комнате Leylak (Приватная столовая) должны пройти по коридору к СВ лестничному пролету и выйти через главные двери фойе, налево.` : `Leylak Room - Those present in the Leylak Room (Private Dining Room) should
                                    proceed down the corridor to the North East Stairwell and exit through the foyer
                                    main doors to the left.`}

                                    </p>
                                    <b>
                                        {languageManagement ? `*Северо-восточный лестничный пролет не указан на карте, но он явно обозначен напротив СВ лифтов.` : `*North East Stairwell is not indicated on the map but it is clearly marked
                                    across from the NE Elevators.`}


                                    </b>
                                </div>
                            </div>


                        </div>
                        <div className={styles.ep__footer}>
                        </div>
                    </div>
                </div>
                {popupIsOpen && <div className={'popup-image'} onClick={() => setPopupIsOpen(false)}>
                    <AppPopup image={activeImgPopup} closePopup={() => setPopupIsOpen(false)}/>
                </div>}
            </div>
        </>

    );
};

